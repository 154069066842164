<template>
  <article class="align-center">
    <section class="protocol-content">
      <strong class="align-center font-size-20 mb-2rem" style="color:#ff6487">STARLINK 商家合作协议</strong>
      <article class="align-left">
        <h1>甲方(采购方)： STARLINK TECHNOLOGY CO.,LIMITED</h1>
        <p>
          <strong>乙方(供应方)：</strong>
        </p>
        <p>
          <strong>鉴于：</strong>
        </p>
        <p class="word-indent">
          <strong>
            甲方及其关联公司是从事跨境电子商务的企业，其品牌字母和/或图形商标的合法所有者，拥有面向全球销售的 App 软件、网站、第三方平台店铺以及不间断开发的网站和
            App 软件(以下统称“starlink销售平台”)的所有权。
          </strong>
        </p>
        <p class="word-indent">
          <strong>
            乙方（供应方）同意：（1）乙方在线确认本协议过程中，一经勾选“我已仔细阅读并同意协议”并点击“续签协议”或“签约”，即意味着乙方同意与甲方及其关联公司签订本协议并自愿受本协议约束，包括但不限于本协议正文及所有在
            starlink销售平台已经发布的或将来可能发布的关于服务的各类规则、规范、公告、说明和（或）通知等，并作为本协议不可分割的一部分，与协议正文具有同等法律效力。（2）同意将其合法所有/被授权的品牌/
            商标/著作权(版权)/专利免费授权/转授权给甲方及其关联公司用于 starlink
            销售平台和线上、线下的营销推广使用。如本协议有效期内乙方新获得品牌/商标/著作权(版权)/专利，
            自权利获取日将自行全部免费授权/转授权给甲方及其关联公司用于营销推广使用。（3）
            乙方已知悉并特别注意本协议中关于双方的责任限制或免责，争议解决，法律适用等影响乙方重大利益的条款，具体条款以加粗并加下划线的形式予以体现。
          </strong>
        </p>
        <p class="word-indent">
          <strong>综上，甲乙双方就乙方向甲方供货销售事宜签订如下条款，以资共同遵守：</strong>
        </p>
        <h2 class="font-size-16">一、合同签订主体</h2>
        <p>（一）乙方签订本协议的条件</p>
        <ol>
          <li>(1)乙方系依照其所属国法律规定合法注册经营并领取合法有效的营业执照和其他经营许可资质的经营主体；</li>
          <li>(2)签订本协议时，乙方已充分知悉甲方所从事的市场活动和货物境外销售地域；</li>
          <li>(3)乙方已明确知悉并同意遵守本协议和甲方（含甲方关联公司）供应链管理规定及starlink销售平台的相关规则。</li>
        </ol>
        <p>（二）乙方须根据甲方要求向甲方提供各类资质文件，包括但不仅限于营业执照、商标注册(授权)证书、质检报告、检验检疫证书、产品来源地证明等。</p>
        <p>（三）乙方须确保所提供的各类资质文件合法、准确并有效，资质文件发生变更时，乙方须及时向甲方提交变更后的文件。如乙方未能及时提交变更，给甲方或第三方造成的所有损失（含政府部门的处罚），均由乙方承担，且甲方有权单方通知解除本协议。</p>
        <h2 class="font-size-16">二、供应商管理系统</h2>
        <p>（一）乙方在使用甲方供应商管理系统时，应妥善保管用户名和密码，不得擅自转让或授权第三方使用，并对在该用户名下所产生的一切行为承担责任。</p>
        <p>（二）供应商管理系统由甲方及关联企业独立开发并拥有完整的知识产权。乙方在使用供应商管理系统时，不得擅自通过各种方式获取系统的源代码，也不得未经甲方书面同意，让第三方学习、使用该系统。</p>
        <p>（三）如乙方违反上述约定或供应商管理系统的相关使用规定，甲方有权解除本协议，并要求乙方承担人民币【贰拾萬元】的违约金，且因乙方违约造成甲方或第三方损失的，甲方有权要求乙方单方承担由此产生的全部赔偿责任。</p>
        <h2 class="font-size-16">三、商品信息规定</h2>
        <p>
          （一）乙方须向 starlink
          销售平台上传所需销售的商品信息，包括但不仅限于商品图片、尺码、材质、使用说明、注意事项和商品详情文案等(具体按甲方对商品信息内容规范确定)，
          以供starlink销售平台对乙方所提供的商品进行展示、推广和销售时使用。
        </p>
        <p>（二）乙方提供的商品信息须真实、合法，须保证甲方在商品展示、推广和销售过程中免于承担对第三方的侵权责任。</p>
        <p>（三）在本协议有效期内，乙方应遵守甲方及其关联公司对商品的调样选品、商品管理和品控监管等各项供应链管理规定，如有违反，甲方有权按供应链管理规定进行处理。</p>
        <p>（四）经甲方翻译销售的商品信息，未经甲方同意的情况下，乙方不得擅自转载到同类竞争平台，否则视为乙方严重违规，甲方有权向乙方索赔违约金，金额等同于乙方在该违规行为前三个月的【月均采购金额】，并将乙方商品下架。</p>
        <h2 class="font-size-16">四、供货商品要求</h2>
        <p>（一）乙方保证其所供商品全部是乙方合法所有的商品，不存在任何侵犯第三方所有权或知识产权的情形，且同时符合法律、法规和行业规范；如发生商品权属或知识产权或商品质量纠纷的，由乙方承担全部责任，甲方有权单方通知解除本协议。</p>
        <p>（二）乙方保证所供货商品应符合乙方的商品信息内容和/或符合甲方调样确认的商品信息，如出现实际供货质量等内容与商品信息和调样商品信息不符，乙方须承担违约责任。</p>
        <p>（三）乙方的供货商品保证不应存在任何危及人身、财产安全的现实或潜在危险，否则由乙方承担全部赔偿责任；如供货商品出现设计和/或商品缺陷，可能会造成人身、财产危害的，乙方应及时采取防止危害等措施，包括但不仅限于召回和/或退货。</p>
        <h2 class="font-size-16">五、供货商品备货</h2>
        <p>（一）乙方须确保在starlink销售平台销售的商品货源充足，能满足甲方的采购需求。当甲方向乙方询问商品货源时，乙方须如实告知，以确保甲方采购商品时乙方货源准确无误。</p>
        <p>（二）乙方预计货源将发生脱销或库存不足时，应提前【7个工作日】采用书面或电子邮件通知甲方并在供应商门户系统中更新状态，以便甲方有足够时间采取应对措施。</p>
        <h2 class="font-size-16">六、商品标签和包装</h2>
        <p
          class="word-indent"
        >乙方应通过供应商门户系统中打印商品标签条码，按照甲方及其关联公司的要求对商品进行包装和粘贴标签条码。如乙方未按规定打印、粘贴标签条码和包装商品的，甲方有权按供应链管理规定进行处理，并由乙方承担甲方为此产生的全部支出。</p>
        <h2 class="font-size-16">七、订单和发货</h2>
        <p>（一）乙方使用供应商管理系统时，须及时登入供应商管理系统并定时刷新以确保实时接收甲方采购订单。</p>
        <p>（二）乙方在接收采购订单时，应及时向甲方反馈采购订单中的商品缺(断)货、延迟发货等供货信息。</p>
        <p>（三）乙方的供货商品须按甲方的要求包装并自检合格，同时附上装箱清单，方可打包发货。</p>
        <p>（四）乙方须按照甲方的采购订单需求按时完成配货，并在【36小时】内发货；乙方须在供货商品发货当日，通过供应商管理系统向甲方提交发货的货物SKU、数量和物流快递单号，以备甲方的仓储人员收货时验收核对。</p>
        <p>（五）乙方的供货商品，因物流、缺货等原因导致甲方【10天】内未收到货品的发货单将自动关闭，由此产生的费用由乙方全部承担。</p>
        <h2 class="font-size-16">八、运输和收验货</h2>
        <p>（一）乙方按本协议发货时效要求，将供货商品快递运送至甲方指定仓储，物流费用由乙方承担。</p>
        <p>（二）因乙方原因或在甲方收货后【7天】内发现乙方供货商品存在错发、漏发、破损(无论是何种原因的破损)或质量问题的，乙方须在收到甲方通知后【24小时】内给予退换货或补发货，由此产生的费用(含运费)由乙方承担。</p>
        <p>（三）因starlink销售平台销售商品时，对用户收货存在时效承诺，如来货异常(包括但不仅限于错发、漏发、破损、缺少配件或存在质量问题等)，将会严重耽误甲方整体物流时效，因此当乙方出现来货异常时，甲方有权按供应链管理规定对乙方进行处理。</p>
        <h2 class="font-size-16">九、客服和售后规范</h2>
        <p>（一）乙方根据甲方的需求，提供相应的商品知识培训，包括但不仅限于提供商品相关培训资料、培训视频等。由甲方客服统一受理用户对供货商品的投诉或退换货事宜。</p>
        <p>（二）如因乙方供货商品质量不合格或不符合乙方提供的商品信息或甲方调样确定的商品信息或存在侵犯知识产权的情形，如甲方先行垫付了因前述情况造成的用户退货费用的，则甲方有权从乙方的货款中直接扣除前述所发生的各项支出(包括但不仅限于供货商品退款、物流费用等)。</p>
        <p>（三）如乙方屡次出现供货商品质量不合格或不符合乙方提供的商品信息或甲方调样确定的商品信息的或存在侵犯知识产权的，甲方有权单方要求乙方缴纳人民币【十万元】品质保证金。甲方在本协议解除或终止后一个月内无息退还剩余品质保证金给乙方。</p>
        <h2 class="font-size-16">十、供货价格</h2>
        <p>（一）乙方的供货商品，须在供应商管理系统中提交商品供货价目信息。</p>
        <p>（二）乙方提交的商品供货价均以甲方确认采购时的定价为准。如乙方按照甲方确定的采购定价发货的，则视为乙方接受了甲方对乙方要约的更改。</p>
        <h2 class="font-size-16">十一、货款支付</h2>
        <p>（一）甲乙双方约定按人民币定价，由甲方根据乙方收款渠道选择【美元或人民币】结算，如选择美元结算，按汇款时的中国人民银行中间折算价结算货款。</p>
        <p>（二）结算前甲乙双方按约定账期对货款进行核对，但应扣除乙方到货异常(包括但不仅限于缺货、少货、货损、到货不符、违约责任、赔偿责任等)，最终以甲乙双方供应商管理后台确认一致的结算金额为准。</p>
        <p>
          （三）乙方同意甲方以甲方名义或通过双方共用的结算平台名义或甲方委托第三方支付的款项，均视为甲方已履行了货款支付义务。乙方充分认可并应妥善保存甲方或共用结算平台出具的收/付款水单、收/付款通知及其他足以证明甲乙双方货款往来的材料作为甲方货款支付的依据。
          （备注：如乙方为个体工商户，须持个体工商户营业执照在银行或结算平台进行开户，甲方以该开户信息为准）
        </p>
        <p>（四）乙方对货款支付结果存有异议时，应当立即书面告知甲方。如单个账期内甲方或双方共用的结算平台付款完成后【2日】内，乙方未提起书面异议或继续履行供货义务，则视为乙方已认可该期货款支付结果。</p>
        <p>（五）因第三方原因导致的支付问题包括但不限于货款迟延、金额不符、未实际到账等，甲乙双方均不承担违约责任，但应积极友好协商解决。</p>
        <p>（六）乙方已充分知悉，甲方只认可符合收款帐户说明的指定收款账户。乙方如提供其他账户，甲方有权拒付货款且不承担违约责任直至乙方提供符合甲方要求的收款账户。乙方如变更公司及股东信息，必须及时与甲方沟通，更换与甲方合作的结算账户，否则因此产生的所有法律责任由乙方公司承担。</p>
        <p>（七）在结算周期内，若乙方发生违规违约行为致使甲方或消费者造成损失的，依据平台管理规则及主合同约定，甲方有权扣除部分或全部销售款，销售款不足以弥补损失的，甲方有权继续要求乙方赔偿由此发生的全部损失。</p>
        <h2 class="font-size-16">十二、Invoice 发票</h2>
        <p
          class="word-indent"
        >在甲方付款前，乙方应按照双方对账确定的货款金额，向甲方先行开具增值税发票或Invoice形式发票。如乙方未及时开具发票或所开发票不符合甲方要求的，甲方有权拒付任何款项直至乙方提供合格的发票后【7】个工作日内。</p>
        <h2 class="font-size-16">十三、starlink销售平台规则</h2>
        <p
          class="word-indent"
        >乙方接受并遵守甲方starlink销售平台上公示的各项规则，并保证向甲方提供的所有商品信息和其发布于甲方销售平台上的信息真实、准确、合法，符合公序良俗。</p>
        <h2 class="font-size-16">十四、商誉</h2>
        <p>（一）甲、乙双方切实维护本协议另一方的商誉和企业形象，不得作出有损于对方商誉的行为。</p>
        <p>
          （二）协议一方发现另一方有诋毁或损害对方商誉的行为时，均有权解除本协议，受损方无论是否解除协议，均可要求对方赔礼道歉和承担人民币一定金额的违约金；
          如协议一方因诋毁或损害行为造成另一方损失的，违约金不足以支付该损失时，受害方有权要求损害方承担损害赔偿责任。
        </p>
        <h2 class="font-size-16">十五、知识产权</h2>
        <p>
          （一）本协议任何一方不得擅自使用或教唆他人使用本协议相对方的企业名称、商标、LOGO、中英文标识、版权(著作权)、专利等。如违反，则违约方应承担人民币【五十万元】
          违约金并向守约方支付全部违约或违法所得。
        </p>
        <p>
          （二）本协议任何一方不得擅自或教唆他人在其所在地或他国或地区申请注册与本协议相对方相同、相似、音同、中英文、近似或相关的商标，也不得申请协议相对方的专利。如有违反，则违约方要承担一定的违约赔偿，并立即无偿转让全部的知识产权归守约方所有，
          并向守约方支付全部违约或违法所得。
        </p>
        <p>（三）本协议一方的商品或商品信息侵犯第三方知识产权的，由该商品或商品信息的提供方自行承担全部责任并承担一定金额的违约金；如造成本协议另一方(非商品或商品信息的提供方)承担责任(无论是民事责任还是行政责任)的，均由提供商品或商品信息的一方承担最终法律责任。</p>
        <p>（四）当乙方违反知识产权约定时，甲方有权单方通知解除本协议。</p>
        <h2 class="font-size-16">十六、商业秘密</h2>
        <p>（一）在履行本协议过程中，本协议任何一方知悉相对方的商业秘密(包括但不仅限于经营状况、商业模式、客户资料、数据、知识产权信息等)时，知悉方须严格保密，不得擅自泄露或使用。</p>
        <p>（二）如违反商业秘密约定，违约方应承担一定的违约金，如违约金不足以弥补损失时，违约方还应赔偿全部损失。无论本协议是否无效、解除或终止，甲乙双方均负有保密责任，直至该商业秘密失密为止。</p>
        <h2 class="font-size-16">十七、解除与终止</h2>
        <p>（一）本协议自乙方点击“续签协议”或“签约”后生效，有效期至【2021年12月31日】止。</p>
        <p>（二）乙方承诺未隐瞒任何足以影响甲方签订和履行本协议的信息。甲方有权在本协议有效期内，依据市场销售情况及时与乙方沟通，对本协议中部分条款进行修改，乙方应配合与协商。如协商后无法达成修改，甲方有权单方通知解除本协议。</p>
        <p>（三）任何一方严重违反本协议约定，且经另一方书面通知后仍不予纠正时，另一方有权以书面通知形式提出解除本协议。</p>
        <p>（四）本协议终止后，甲乙双方应按本协议继续履行售后、货款结算等事宜，待乙方供货商品无任何问题时，甲方在最终结算后【3个月】内支付全额剩余款项。</p>
        <h2 class="font-size-16">十八、争议处理</h2>
        <p>（一）本协议约定适用中华人民共和国(大陆)的法律。</p>
        <p>（二）在履行本协议过程中发生争议的，甲乙双方须友好协商解决。协商不成的，提交【甲方】所在地人民法院解决。</p>
        <h2 class="font-size-16 align-center">附则：供应商合作管理规定（成衣型供应链条）</h2>
        <p>一、总则</p>
        <ol>
          <li>1、为了提高工作效率，稳定供应商队伍，保证公司采购商品及服务的连贯稳定，建立长期互惠供求关系，特制定本规定。</li>
          <li>2、本规定适用于公司pop商家(联营)供应商体系下所有已签订合同的供应商。</li>
        </ol>
        <p>二、管理要求</p>
        <ol>
          <li>1、时效要求：系统下单后5天到货率90%以上。</li>
          <li>2、质量要求：新款商品进行全面验样，订单退货率2%以下。</li>
          <li>3、开发要求：与公司开发的专供合作款不得提前外泄(需重新签订商品竞业合同)。</li>
        </ol>
        <p>三、供应商的筛选与评级</p>
        <ol>
          <li>1、开发能力：由商品开发部门负责打分；</li>
          <li>2、质量水平：由仓储品控部门负责打分（包含样衣质量、退货率、对质量问题的处理等）；</li>
          <li>3、交货能力：由成衣采购部门负责打分（包含样衣时效、大货时效、异常处理配合度等）；</li>
          <li>4、价格水平：由商品开发部门负责打分；以上评级季度一次，由公司分管总监监督完成全流程打分，综合分将决定供应商下季度开发量及订单量。</li>
        </ol>
        <p>四、拜访管理措施</p>
        <ol>
          <li>1、公司对重要供应商会派遣寻厂，或经常时效及质量不达标供应商进行质量寻检。</li>
          <li>2、公司商品开发及成衣采购部门负责人每季度会进行正式回访，回访后应填写回访表上交公司系统。</li>
        </ol>
        <p>五、奖惩措施</p>
        <ol>
          <li>1、供应商评级排名前30供应商可享受优先专供款开发资源及开发上新数量资源；</li>
          <li>2、时效未达标的订单按照每件0.5元进行扣款；</li>
          <li>3、退货率未达标的订单按照每件0.3元进行扣款。</li>
        </ol>
        <div class="align-center">
          <el-checkbox v-model="checked">我已仔细阅读并同意协议</el-checkbox>
        </div>
      </article>
    </section>
    <footer>
      <el-button type="primary" :disabled="!checked" :loading="isLoading" @click="protocolSubmit">签约</el-button>
    </footer>
  </article>
</template>

<script>
import UserApi from '@api/user'

export default {
  name: 'Protocol',
  props: {

  },
  data () {
    return {
      checked: false,
      isLoading: false
    }
  },
  computed: {

  },
  methods: {
    protocolSubmit () {
      this.isLoading = true
      UserApi.protocolConfirm().then(res => {
        if (res.success) {
          this.$store.dispatch('user/UPDATE_ROUTES').then(() => {
            this.$router.push('home/recommend-products/list')
          })
        }
      }).finally(() => {
        this.isLoading = false
      })
    }

  },
  mounted: function () {

  }
}
</script>

<style scoped lang="scss">
.protocol-content {
  position: relative;
  display: inline-block;
  padding: 1em;
  margin-bottom: 1em;
  line-height: 2;
  max-height: calc(100vh - 300px);
  box-shadow: 0 0 1rem #eee;
  overflow: auto;
  & ol :not(.root) li {
    margin-left: 1em;
  }
}

.word-indent {
  text-indent: 2em;
}
</style>
